<template>
	<div class="tabs-container-large">
		<div class="tabs-wrapper">
			<CTabs>
				<CTab
					v-for="(item, index) in components"
					:key="index"
					:title="item.title"
					:active="item.routerTo === $router.currentRoute.name"
					:to="{ name: item.routerTo }"
				>
					<div class="c-main">
						<div class="container-fluid">
							<component
								:is="item.component"
								:is-loading="isLoading"
								:data="items"
								:meta="itemList.meta"
								:create-link="item.routerToCreate"
								:edit-link="item.routerToEdit"
								:widget-options="widgetOptions"
								:banner-type="bannerType"
								:content-block-type="contentBlockType"
							/>
						</div>
					</div>
				</CTab>
			</CTabs>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import WidgetList from '@/components/WidgetList.vue';
import BannerGroupList from '@/components/BannerGroupList.vue';
import BannerProductCategory from '@/components/BannerProductCategory.vue';
import ContentBlockList from '@/components/ContentBlockList.vue';

import { HOMEPAGE_TABS, HOMEPAGE_COMPONENTS, HOMEPAGE_GROUP_TYPES } from '../enums/homepage';
import { BANNER_TYPE } from '../enums/banners';
import { WIDGET_TYPE } from '../enums/widgets';
import { CONTENT_BLOCK_TYPE, CONTENT_BLOCK_WIDGET_TYPE, CONTENT_BLOCK_TYPE_API } from '../enums/contentBlocks';

export default {
	name: 'Homepage',

	components: {
		WidgetList,
		BannerGroupList,
		BannerProductCategory,
		ContentBlockList,
	},
	props: {
		groupType: {
			type: String,
			default: HOMEPAGE_GROUP_TYPES.BANNER,
		},
		bannerType: {
			type: String,
			default: BANNER_TYPE.hero,
		},

		// CONTENT_BLOCK_TYPE
		contentBlockType: {
			type: String,
			default: CONTENT_BLOCK_TYPE.HIGHLIGHT,
		},
	},
	data() {
		return {
			isLoading: false,
			queryParams: {
				q: this.$route.query.q || null,
				start_at: this.$route.query.start_at || null,
				end_at: this.$route.query.end_at || null,
				widget_id: Number(this.$route.query.widget_id) || null,
				page: Number(this.$route.query.page) || null,
				per_page: Number(this.$route.query.per_page) || null,
				is_visible: (Number(this.$route.query.is_visible) === 0 || Number(this.$route.query.is_visible) === 1)
					? Number(this.$route.query.is_visible)
					: null,
			},
		};
	},
	computed: {
		...mapState('banners', {
			bannerList: 'list',
		}),

		...mapState('contentBlocks', {
			contentBlockList: 'list',
		}),

		...mapState('widgets', {
			widgetList: 'list',
		}),

		...mapGetters({
			getBannersGroupList: 'banners/getBannersGroupList',
			getContentBlocksList: 'contentBlocks/getContentBlocksList',
		}),

		components() {
			return HOMEPAGE_TABS.map((tab) => HOMEPAGE_COMPONENTS[tab]);
		},

		widgetType() {
			switch (this.groupType) {
				case HOMEPAGE_GROUP_TYPES.CONTENT_BLOCK:
					return WIDGET_TYPE[CONTENT_BLOCK_WIDGET_TYPE[this.contentBlockType]];

				default:
					return WIDGET_TYPE[this.bannerType];
			}
		},

		widgetOptions() {
			const allOption = { name: 'All widgets', value: null };
			const list = this.widgetList.data
				.filter((widget) => widget.type === this.widgetType)
				.map((widget) => ({
					name: widget.name,
					value: widget.id,
				}));

			return [allOption, ...list];
		},

		items() {
			switch (this.groupType) {
				case HOMEPAGE_GROUP_TYPES.CONTENT_BLOCK:
					return this.getContentBlocksList;

				default:
					return this.getBannersGroupList;
			}
		},

		itemList() {
			switch (this.groupType) {
				case HOMEPAGE_GROUP_TYPES.CONTENT_BLOCK:
					return this.contentBlockList;

				default:
					return this.bannerList;
			}
		},
	},
	async created() {
		const path = this.$router.currentRoute.path;
		const widgets = this.getWidgets({
			type: this.widgetType,
			per_page: 'all',
		});

		if (path !== 'homepage') {
			const asyncTasks = [widgets];

			if (this.groupType === HOMEPAGE_GROUP_TYPES.BANNER) {
				const bannerGroup = this.getBannersGroup({
					...this.queryParams,
					type: this.bannerType,
				});

				asyncTasks.push(bannerGroup);
			} else if (this.groupType === HOMEPAGE_GROUP_TYPES.CONTENT_BLOCK) {
				const contentBlock = this.getContentBlocks({
					...this.queryParams,
					// TODO: [APP-XXXX] have to change type content block type to api in API
					type: CONTENT_BLOCK_TYPE_API[this.contentBlockType],
				});

				asyncTasks.push(contentBlock);
			}

			this.isLoading = true;

			await Promise.all(asyncTasks);

			this.isLoading = false;
		}
	},
	methods: {
		...mapActions({
			getWidgets: 'widgets/getWidgets',
			getBannersGroup: 'banners/getBannersGroup',
			getContentBlocks: 'contentBlocks/getContentBlocks',
		}),
	},
};
</script>
