var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('BaseLoading'):_c('div',[_c('BannerCreateAndFilter',{ref:"create-and-filter",attrs:{"create-link":_vm.createLink,"widget-options":_vm.widgetOptions,"found":_vm.meta.total,"type-found-text":"Content Block","content-block-type":_vm.contentBlockType,"create-button-text":_vm.$t('global.general.create', { type: 'content block' }),"placeholder-text":_vm.$t('global.general.searchPlaceholder', { type: 'content block' })}}),_c('CRow',[_c('CCol',[_c('BaseTable',{staticClass:"table-custom table-custom-link",attrs:{"pagination":{
					pages: _vm.meta.lastPage,
					activePage: _vm.meta.currentPage,
				},"fields":_vm.tableFields,"items":_vm.dataTable,"clickable-rows":"","link-to":_vm.editLink,"vertical-align":"top"},on:{"onPaginationClick":_vm.handlePageChange},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('div',{staticClass:"empty-table-element"},[_c('p',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t('global.searchNotFound', { field: 'content block', }))+" ")])])]},proxy:true},{key:"name",fn:function(ref){
				var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center content-block-name"},[(_vm.hasImage)?_c('img',{staticClass:"thumbnail-40 mr-3",attrs:{"src":item.thumbnail}}):_vm._e(),_c('strong',[_vm._v(_vm._s(item.name))])])]}},{key:"period",fn:function(ref){
				var item = ref.item;
return [_c('CBadge',{staticClass:"badge-status",attrs:{"color":_vm.BANNER_PERIOD_COLOR[item.value]}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"widgets",fn:function(ref){
				var item = ref.item;
return [(item.length)?_c('ul',{staticClass:"widget-list"},_vm._l((item),function(widget,key){return _c('li',{key:key},[_vm._v(" "+_vm._s(widget)+" ")])}),0):[_vm._v(" - ")]]}},{key:"status",fn:function(ref){
				var item = ref.item;
return [_c('CBadge',{staticClass:"badge-status",attrs:{"color":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }