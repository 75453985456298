<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<form
		v-else
		class="main-wrapper mt-4"
		@submit.prevent="handleSubmit"
	>
		<CRow class="mb-5">
			<CCol md="12">
				<div class="d-block">
					<label class="mb-0">
						<h3 class="typo-label">Sort Category</h3>
						<p class="typo-caption color-black-45">Drag to reposition. It will be<br> displayed in slide category banner specification section.</p>
					</label>
				</div>
			</CCol>
			<CCol lg="12 mt-4">
				<div v-if="!keys.length" class="empty-key">
					There are no attributes in this set
				</div>
				<CategoryDraggable
					v-else
					v-model="keys"
					@onChange="handleKeyChange"
				/>
			</CCol>
		</CRow>

		<BaseActionPanelStickyFooter
			content-class="main-wrapper"
			is-edit
			disabled-cancel
			data-test-id="product-category-banner-set"
			@onConfirm="handleSubmit"
		/>

		<BaseModalConfirm
			ref="modal-confirm"
			:is-submitting="categoryBanner.isUpdating"
			title="Save confirmation?"
			description="All changes will take effect in 5 minutes."
			primary-button-text="Confirm save"
			primary-button-loading-text="Saving"
			@onConfirm="handleConfirm"
		/>
	</form>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import CategoryDraggable from '@/components/CategoryDraggable.vue';

export default {
	name: 'BannerProductCategory',
	components: {
		BaseModalConfirm,
		CategoryDraggable,
	},
	data() {
		return {
			isLoading: false,
			keys: [],
		};
	},
	computed: {
		...mapState('categories', {
			categoryBanner: 'banner',
		}),
	},

	async created() {
		this.isLoading = true;
		// fetch data
		await this.getCategories();
		await this.getSortingCategoryBanner();

		this.isLoading = false;

		// applied fetch data to keys
		this.keys = this.categoryBanner.data;
	},
	methods: {
		...mapActions({
			getCategories: 'categorySelector/getCategories',
			updateSortingCategoryBanner: 'categories/updateSortingCategoryBanner',
			getSortingCategoryBanner: 'categories/getSortingCategoryBanner',
		}),
		handleKeyChange(keys) {
			this.keys = keys;
		},
		handleSubmit() {
			this.$refs['modal-confirm'].open();
		},
		async handleConfirm() {
			const categoryKeys = this.keys.map((key) => key.id);
			await this.updateSortingCategoryBanner({
				params: {
					category_ids: categoryKeys,
				},
			});
			this.$refs['modal-confirm'].close();
		},
	},
};
</script>

<style lang="scss" scoped>
	.set-id {
		color: $color-black-45;
		font-weight: $font-weight-bold;
	}

	.marketing-category-name {
		flex: 1;
		max-width: 70%;
	}

	.empty-key {
		@include typo-subtitle;

		display: flex;
		align-items: center;
		justify-content: center;
		height: rem(138);
		color: $color-black-45;
		background-color: $color-gray-100;
	}
</style>
